import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

function dev() {
  return (
    <Layout>
      <SEO title="Dev Page" />
      <div className="wrapper">
        <h1>ALTO CUSTOM HOMES H1</h1>
        <p>
          Stunning views with gently rolling greens, clusters of mature trees,
          ponds, and wildlife. Calm, quiet evenings on your deck or patio.
          Carefree living in a quality, custom home. Peaceful neighborhood with
          well manicured landscaping. Close knit social community with various
          social activities. Charming clubhouse with dining, golf shop and bar.
        </p>
        <p>
          Peaceful neighborhood with well manicured landscaping. Close knit
          social community with various social activities. Charming clubhouse
          with dining, golf shop and bar.
        </p>
      </div>
    </Layout>
  )
}

export default dev
